import React, {useEffect, useMemo, useRef, useState} from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { Select, InputLabel, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { BeatLoader } from 'react-spinners';
import ErrorIcon from '@mui/icons-material/Error';
import { Typography } from '@mui/material';
import axios from 'axios';

import { errorService } from 'services/alert/services';
import { useRanks } from 'hooks/use-ranks';

import { dialogStyles } from './styles';
import { tournamentType, isType, isMode, modes } from './utils';
import { ETournamentType } from 'domain/tournament';
import { TournamentService } from 'services/api/tournament';
import { useStore } from 'hooks/use-store';
import UploadImageInput from 'components/UploadImageInput';
import { EGameFen } from 'domain/game/game.types';
import {endpointsRoot} from "../../api/endpoints";
import {useAuth} from "../../hooks/use-auth";

const availableValues = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024];

const defaultPrizes = [
  // {
  //   amount: 0,
  //   rating: 0,
  //   place: 1,
  //   range: null,
  // },
];


const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: +values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
};

const timeControlList = [
  {
    time: 1,
    increment: 1,
    label: '1',
  },
  {
    time: 2,
    increment: 0,
    label: '2',
  },
  {
    time: 3,
    increment: 3,
    label: '3',
  },
  {
    time: 5,
    increment: 5,
    label: '5',
  },
  {
    time: 10,
    increment: 0,
    label: '10',
  },
  {
    time: 20,
    increment: 0,
    label: '20',
  },
];

const defaultTimeControl = { time: 5, increment: 5 };

const ErrorMessage = ({ message }) => {
  return <div style={{ color: 'red', fontSize: 12 }}>{message}</div>;
};

const CreateOrUpdateTournamentModal = ({ open, handleClose, onSubmit, mode, tournamentId }) => {
    const auth = useAuth();
  const styles = dialogStyles();
  const { data: ranks, isLoading, isError } = useRanks();
  const [tournament, setTournament] = useState<any>(null);
  const [isAddingUser, setAddingUser] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [mes, setMes] = useState('');
  const { tournamentTemplates } = useStore();

  const inputRef = useRef(null);


  const sendForm = (eve: any) => {
    eve.preventDefault();


    if (inputRef.current) {
        const value = (inputRef.current as HTMLInputElement).value;

        if (value) {
            setLoading(true);
            axios({
                method: 'post',
                url: endpointsRoot + '/api/3/tournament/add-user',
                headers:  { 'Content-Type': 'application/json', Authorization: `Bearer ${auth.token}` },
                data: {
                    name: value,
                    tournamentId: tournamentId
                },
            }).then((res) => {
                const user = res.data;


                setLoading(false);
                if (user.err) {
                    setMes(user.err);
                } else {
                    setMes(user.nickname + ' ' + user.email + ' added');
                }

                const tm = setTimeout(() => {
                    setMes('');

                    clearTimeout(tm);
                }, 2000);

            }).catch((err) => {
                setMes(err.message);
                setLoading(false);

                const tm = setTimeout(() => {
                    setMes('');

                    clearTimeout(tm);
                }, 2000);
            });
        }

    }
  };

  useEffect(() => {
    if (tournamentId) {
      getTournament();
    }

    return () => {
      setTournament(null);
    };
  }, [tournamentId]);

  const getTournament = async () => {
    try {
      const response = await TournamentService.getByID(tournamentId);
      setTournament(response.data.tournament);
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const initialValues = useMemo(
    () => ({
      title: tournament?.title ?? '',
      description: tournament?.description ?? '',
      imageURL: tournament?.imageURL ?? '',
      prizes: tournament?.prizes ?? defaultPrizes,
      buyIn: tournament?.buyIn ?? 0,
      places: tournament?.places ?? 0,
      maxPlayers: tournament?.maxPlayers ?? 2,
      botCount: tournament?.botCount ?? 0,
      maxPlayersRestrict: tournament?.maxPlayersRestrict ?? false,
      isPrivate: tournament?.isPrivate ?? false,
      matchCount: tournament?.gameSettings?.matchCount ?? 1,
      fenType: tournament?.gameSettings?.fenType ?? EGameFen.FEN_NORMAL,
      type: tournament?.type ?? ETournamentType.PLAY_OFF,
      fromRank: tournament?.fromRank ?? '',
      startAt: tournament?.startAt ? moment(tournament.startAt).format('YYYY-MM-DDTHH:mm') : '',
      time: tournament?.gameSettings?.time ?? defaultTimeControl.time,
      registrationAt: tournament?.registrationAt
        ? moment(tournament?.registrationAt).format('YYYY-MM-DDTHH:mm')
        : null,
      startBalance: tournament?.startBalance ?? 0,
      startBank: tournament?.gameSettings?.startBank ?? 0,
    }),
    [tournament],
  );

  const handleAddOnePrize = (prizes, setFieldValue) => {
    const lastPrize = prizes[prizes.length - 1];
    const newPrize = {
      amount: 0,
      rating: 0,
      place: lastPrize && (lastPrize?.place === 4 || lastPrize?.place == null) ? null : (lastPrize?.place || 0) + 1,
      range:
      lastPrize?.place === 4
          ? [4, 8]
          : (lastPrize && lastPrize?.place) == null
          ? lastPrize?.range.map((r) => r * 2)
          : null,
    };
    setFieldValue('prizes', [...prizes, newPrize]);
  };

  const handleDeleteOnePrize = (prizes, setFieldValue) => {
    // const lastPrize = prizes[prizes.length - 1];
    // if (lastPrize.place !== 1) {
      setFieldValue('prizes', [...prizes.slice(0, -1)]);
    // }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle id="form-dialog-title">
            <div className={styles.topNav}>
              {isMode(mode, modes.update) ? 'Обновить' : 'Создать'} турнир

              {isMode(mode, modes.update) && <div className={styles.add} onClick={() => setAddingUser(!isAddingUser)}>{isAddingUser ? 'edit tournament' : 'add user'}</div>}
            </div>
        </DialogTitle>
        <DialogContent>
            <div className={isAddingUser ? styles.hidden : undefined}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Введите название'),
              description: Yup.string(),
              buyIn: Yup.number().required('Введите Сумму buyIn'),
              botCount: Yup.number().required('Введите кол-во ботов'),
              prizes: Yup.array().of(
                Yup.object().shape({
                  amount: Yup.number('Должно быть числом').required('Введите сумму приза'),
                  place: Yup.number('Должно быть числом').nullable(true),
                  range: Yup.array().of(Yup.number('Must be number')).nullable(true),
                }),
              ),
              matchCount: Yup.number().min(1, 'Минимум - 1').required('Обязательное поле'),
              maxPlayers: Yup.number()
                .min(2, 'Минимальное количество участников -> 2')
                .required('Введите количество игроков')
                .test(
                  'maxPlayers',
                  'Количество игроков должно быть кратно 2',
                  (value) => !!availableValues.find((el) => el === +value),
                ),
              startAt: Yup.date().required('Введите дату турнира'),
              time: Yup.number('Время должно быть числом').required('Введите время игр'),
              type: Yup.string().required('Введите тип турнира'),
              fromRank: Yup.string(),
              registrationStartDate: Yup.date(),
            })}
            onSubmit={(values) => onSubmit(values, initialValues)}
            validateOnChange
            render={({
              values,
              errors,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
            }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl component="fieldset" className={styles.formControl}>
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.title && touched.title)}
                      name="title"
                      margin="dense"
                      label="Название"
                      value={values.title}
                      placeholder="Введите название"
                    />

                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.title ? (errors.title as string) : ''}
                    </div>

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.description && touched.description)}
                      name="description"
                      margin="dense"
                      label="Описание"
                      value={values.description}
                      placeholder="Введите Описание"
                    />
                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.description ? (errors.description as string) : ''}
                    </div>

                    <FormControl className={styles.formControl} size="small">
                      <UploadImageInput
                        image={values.imageURL}
                        onChange={(value) => setFieldValue('imageURL', value)}
                      />
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {touched.imageURL ? (errors.imageURL as string) : ''}
                      </div>
                    </FormControl>

                    <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">Время игр</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.time}
                        label="Время"
                        name="time"
                        onChange={handleChange}
                      >
                        {timeControlList.map((timeControl) => {
                          return (
                            <MenuItem value={timeControl.time} key={timeControl.time}>
                              {timeControl.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    {/* <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">Цвет</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.color}
                        label="Цвет"
                        name="color"
                        onChange={handleChange}
                      >
                        {colors.map((color) => (
                          <MenuItem value={color.value} key={color.value}>
                            {color.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">FEN</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.fenType}
                        label="FEN"
                        name="fenType"
                        onChange={handleChange}
                      >
                        <MenuItem value={EGameFen.FEN_NORMAL}>Классический</MenuItem>
                        <MenuItem value={EGameFen.FEN_960}>960</MenuItem>
                      </Select>
                    </FormControl>

                    {isMode(mode, modes.create) && (
                      <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                        <InputLabel id="demo-select-small">Тип турнира</InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={values.type}
                          label="Тип"
                          name="type"
                          onChange={handleChange}
                        >
                          <MenuItem value={ETournamentType.PLAY_OFF}>PLAY OFF</MenuItem>
                          <MenuItem value={ETournamentType.PLAY_OFF_LONG}>PLAY OFF LONG</MenuItem>
                          <MenuItem value={ETournamentType.LEAGUE}>LEAGUE</MenuItem>
                        </Select>
                      </FormControl>
                    )}

                    <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">Ранг</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.fromRank}
                        label="Ранг"
                        name="fromRank"
                        onChange={handleChange}
                        displayEmpty
                        error={touched.fromRank ? Boolean(errors.fromRank) : false}
                      >
                        {isLoading && (
                          <div className={styles.rankIcon}>
                            <BeatLoader size={16} color="#00acc1" loading={true} />
                          </div>
                        )}
                        {isError && (
                          <div className={styles.rankIcon}>
                            <ErrorIcon className={styles.errorIcon} />
                          </div>
                        )}
                        {ranks &&
                          ranks.map((rank) => (
                            <MenuItem key={rank._id} value={rank._id}>
                              {rank.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    {isMode(mode, modes.create) && (
                      <>
                        <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!(errors.maxPlayers && touched.maxPlayers)}
                          name="maxPlayers"
                          margin="dense"
                          label="Количество игроков"
                          value={values.maxPlayers}
                          placeholder="Введите количество"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                        <div style={{ color: 'red', fontSize: 12 }}>
                          {touched.maxPlayers ? (errors.maxPlayers as string) : ''}
                        </div>
                      </>
                    )}

                      <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!(errors.botCount && touched.botCount)}
                          name="botCount"
                          margin="dense"
                          label="Bot count"
                          value={values.botCount}
                          placeholder="Введите кол-во ботов"
                          InputProps={{
                              inputComponent: NumberFormatCustom,
                          }}
                      />
                      <div style={{ color: 'red', fontSize: 12 }}>
                          {touched.botCount ? (errors.botCount as string) : ''}
                      </div>

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.buyIn && touched.buyIn)}
                      name="buyIn"
                      margin="dense"
                      label="Buy in"
                      value={values.buyIn}
                      placeholder="Введите buy in"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.buyIn ? (errors.buyIn as string) : ''}
                    </div>

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.places && touched.places)}
                      name="places"
                      margin="dense"
                      label="Количество призёров для TBA"
                      value={values.places}
                      placeholder="Количество призёров для TBA"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.places ? (errors.places as string) : ''}
                    </div>

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.matchCount && touched.matchCount)}
                      name="matchCount"
                      margin="dense"
                      label="Серия игр"
                      value={values.matchCount}
                      placeholder="Серия игр"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />

                    <div style={{ color: 'red', fontSize: 12 }}>
                      {(errors.matchCount as string) || ''}
                    </div>

                    {values.type === ETournamentType.LEAGUE && (
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.startBalance && touched.startBalance)}
                        name="startBalance"
                        margin="dense"
                        label="Стартовый баланс пользователей"
                        value={values.startBalance}
                        placeholder="Стартовый баланс пользователей"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    )}

                    {values.type === ETournamentType.LEAGUE && (
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.startBank && touched.startBank)}
                        name="startBank"
                        margin="dense"
                        label="Стартовый банк игр"
                        value={values.startBank}
                        placeholder="Стартовый банк игр"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    )}

                    <div
                      style={{
                        border: '3px solid gray',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '15px 0',
                        padding: '10px 5px',
                      }}
                    >
                      {values.prizes.map((prize, i) => {
                        return (
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr',
                              gridColumnGap: '5px',
                            }}
                          >
                            <TextField
                              key={i}
                              onChange={handleChange}
                              name={`prizes[${i}].amount`}
                              margin="dense"
                              label={`Приз за ${
                                prize.place || `${prize.range[0]}-${prize.range[1]}`
                              } место`}
                              value={values.prizes[i].amount}
                              placeholder="Введите приз"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                            />
                            <TextField
                              key={i}
                              onChange={handleChange}
                              name={`prizes[${i}].rating`}
                              margin="dense"
                              label={`Рейтинг за ${
                                prize.place || `${prize.range[0]}-${prize.range[1]}`
                              } место`}
                              value={values.prizes[i].rating}
                              placeholder="Введите рейтинг"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                            />
                          </div>
                        );
                      })}
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {Array.isArray(errors.prizes) &&
                          errors.prizes.filter(Boolean).map((err: Record<string, any>, i) => {
                            return <span key={i}>{err.amount || err.place}</span>;
                          })}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleAddOnePrize(values.prizes, setFieldValue);
                          }}
                        >
                          <AddIcon />
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleDeleteOnePrize(values.prizes, setFieldValue);
                          }}
                        >
                          <RemoveIcon />
                        </Button>
                      </div>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.maxPlayersRestrict)}
                          name="maxPlayersRestrict"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={
                        <span className={styles.formControlLabel}>
                          Ограничение по кол. игроков?
                        </span>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.isPrivate)}
                          name="isPrivate"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={
                        <span className={styles.formControlLabel}>
                            Приватный турнир
                        </span>
                      }
                    />
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.startAt && touched.startAt)}
                      name="startAt"
                      type="datetime-local"
                      margin="dense"
                      value={values.startAt}
                      label="Дата начала турнира"
                      placeholder="Дата турнира"
                      InputLabelProps={{ shrink: true }}
                    />
                    <ErrorMessage message={touched.startAt ? errors.startAt : ''} />
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="registrationAt"
                      type="datetime-local"
                      margin="dense"
                      value={values.registrationAt}
                      label="Дата начала регистрации"
                      placeholder="Дата начала регистрации"
                      InputLabelProps={{ shrink: true }}
                    />
                    <ErrorMessage message={touched.registrationAt ? errors.registrationAt : ''} />
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.settings?.isPrivate)}
                          name="settings.isPrivate"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={<span className={styles.formControlLabel}>Приватный?</span>}
                    />
                    {values.settings?.isPrivate && (
                      <>
                        <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // @ts-ignore
                          error={!!(errors.settings?.password && touched.settings?.password)}
                          name="settings.password"
                          margin="dense"
                          label="Пароль"
                          value={values.settings?.password}
                          placeholder="Введите пароль"
                        />
                        <ErrorMessage
                          // @ts-ignore
                          message={touched.settings?.password ? errors.settings?.password : ''}
                        />
                      </>
                    )} */}

                    <FormControl style={{ minWidth: 120, width: '100%' }} size="small">
                      <InputLabel id="demo-select-small">Шаблон турнира</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        label="Шаблон"
                        name="templateId"
                        onChange={(event) => {
                          const value = event.target.value as string;
                          const template = tournamentTemplates.find((t) => t._id === value);
                          if (!template) {
                            return;
                          }
                          setFieldValue('title', template.title);
                          setFieldValue('description', template.description);
                          setFieldValue('imageURL', template.imageURL);
                          setFieldValue('prizes', template.prizes);
                          setFieldValue('matchCount', template.gameSettings?.matchCount ?? 1);
                          setFieldValue('maxPlayers', template.maxPlayers);
                          setFieldValue('time', template.gameSettings.time);
                          setFieldValue('type', template.type);
                          setFieldValue('maxPlayersRestrict', template.maxPlayersRestrict)
                          setFieldValue("places", template.places ?? 0)
                        }}
                      >
                        {tournamentTemplates.map((template) => (
                          <MenuItem value={template._id}>{template.title}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>

                  <DialogActions>
                    <Button color="secondary" variant="contained" onClick={() => handleClose()}>
                      Отмена
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => handleSubmit()}>
                      {isMode(mode, modes.update) ? 'Обновить' : 'Создать'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
            </div>
            <div className={!isAddingUser ? styles.hidden : undefined}>
                {!mes.length && !loading && <form onSubmit={sendForm} className={styles.userForm}>
                    <input ref={inputRef} placeholder="User name or user email" className={styles.userFormInput} type="text"/>
                    <button type="submit">Add user</button>
                </form>}
                {loading ? '...' : ''}
                {mes.length && !loading ? mes : ''}
            </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateOrUpdateTournamentModal;
