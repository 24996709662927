import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CardFooter from 'components/Card/CardFooter.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';
import { TransactionService } from 'services/api/transaction';
import { errorService } from 'services/alert/services';
import Checkbox from '@material-ui/core/Checkbox';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UserService } from 'services/api/user';
import dayjs from 'dayjs';

function formatXAxis(tickItem) {
  return dayjs(tickItem).format('DD MMMM YYYY')
}

const useStyles = makeStyles({
  cardBody: {
    display: 'flex',
  },
  root: {
    padding: '13px',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  labelRoot: {
    marginLeft: '-14px',
  },
  checked: {
    color: '#fff!important',
  },

  checkedIcon: {
    width: '20px',
    height: '20px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  uncheckedIcon: {
    width: '0px',
    height: '0px',
    padding: '10px',
    border: '1px solid rgba(0, 0, 0, .54)',
    borderRadius: '3px',
  },
  switchWrapper: {
    marginLeft: '10px',
  },
  datePickerWrapper: {
    maxWidth: 400,
    padding: '10px 20px',
    position: 'relative',

    display: 'flex',
    justifyContent: 'flex-start',
  },
  datePickerInnerWrapper: {
    position: 'relative',
  },
  datePickerLoader: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'grid',
    placeItems: 'center',
    background: 'rgb(0, 0, 0, 0.2)',
  },
  dateRangePicker: {
    zIndex: 10,
  },
});

const lineLabels = {
  slots: {
    lost: 'Проиграно',
    won: 'Выиграно',
    difference: 'Разница',
  },
  liveDealer: {
    lost: 'Проиграно',
    won: 'Выиграно',
    difference: 'Разница',
  },
  poker: {
    lost: 'Заведено',
    won: 'Выведено',
    difference: 'Разница',
    rake: 'Рейк',
  },
  dicechess: {
    won: 'Выиграно',
    rake: 'Рейк',
  },
};

const colors = {
  won: '#ab47bc',
  lost: '#FCDF03',
  difference: '#fff',
  rake: 'pink',
};

export const TransactionsStatsByDays = ({ userId }) => {
  const classes = useStyles();
  const [data, setData] = useState<any>({});
  const [selectedStatsType, setSelectedStatsType] = useState('stats');
  const [dateRangeValues, setDateRangeValues] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getTransactionsStats();
  }, [dateRangeValues]);

  const getTransactionsStats = async () => {
    setIsLoading(true);
    try {
      const { data } = await UserService.getStats(userId, {
        from: dateRangeValues?.[0],
        to: dateRangeValues?.[1],
      });
      setData(data);
    } catch (e) {
      errorService.sendError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const scatterPlotLines = {
    stats: [
      {
        key: "games.played",
        color: "#1E90FF",
        label: "Всего игр"
      },
      {
        key: "games.won",
        color: "#32CD32",
        label: "Победы"
      },
      {
        key: "games.lost",
        color: "#FF4500",
        label: "Поражения"
      },
      {
        key: "rate.winStreak",
        color: "#FFD700",
        label: "Победная серия"
      },
      {
        key: "externalGames.won",
        color: "#FFD700",
        label: "Победная серия"
      }
    ],
    balance: [
      {
        key: "play",
        color: "#FFD700",
        label: "Игровой баланс"
      },
      {
        key: "coins",
        color: "#C0C0C0",
        label: "Бесплатные монеты"
      },
      {
        key: "bonus",
        color: "#000",
        label: "Бонусный баланс"
      },
      {
        key: "rakeBack",
        color: "#FFD700",
        label: "Рейкбэк"
      }
    ],
    dicechess: [
      {
        key: "lost",
        color: "#FCDF03",
        label: "Заведено",
      },
      {
        key: "won",
        color: "#ab47bc",
        label: "Выведено",
      },
      {
        key: "difference",
        color: "#fff",
        label: "Разница",
      },
      {
        key: "rake",
        color: "pink",
        label: "Рейк",
      }
    ],
    slots: [
      {
        key: "lost",
        color: "#FCDF03",
        label: "Заведено",
      },
      {
        key: "won",
        color: "#ab47bc",
        label: "Выведено",
      },
      {
        key: "difference",
        color: "#fff",
        label: "Разница",
      },
    ],
    liveDealers: [
      {
        key: "lost",
        color: "#FCDF03",
        label: "Заведено",
      },
      {
        key: "won",
        color: "#ab47bc",
        label: "Выведено",
      },
      {
        key: "difference",
        color: "#fff",
        label: "Разница",
      },
    ],
    poker: [
      {
        key: "lost",
        color: "#FCDF03",
        label: "Заведено",
      },
      {
        key: "won",
        color: "#ab47bc",
        label: "Выведено",
      },
      {
        key: "difference",
        color: "#fff",
        label: "Разница",
      },
    ],
    algNet: [
      {
        key: "lost",
        color: "#FCDF03",
        label: "Заведено",
      },
      {
        key: "won",
        color: "#ab47bc",
        label: "Выведено",
      },
      {
        key: "difference",
        color: "#fff",
        label: "Разница",
      },
    ],
    evenbet: [
      {
        key: "lost",
        color: "#FCDF03",
        label: "Заведено",
      },
      {
        key: "won",
        color: "#ab47bc",
        label: "Выведено",
      },
      {
        key: "difference",
        color: "#fff",
        label: "Разница",
      },
    ],
  }

  console.log("scatterPlotLines[selectedStatsType]", scatterPlotLines[selectedStatsType], selectedStatsType, data[selectedStatsType])

  return (
    // @ts-ignore
    <GridItem xs={12} sm={12} md={12}>
      <Card chart>
        <CardHeader color="success">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={data[selectedStatsType]}>
              {scatterPlotLines[selectedStatsType].map((p) => (
                <Line
                  key={p.key}
                  name={p.label}
                  type="monotone"
                  dataKey={p.key}
                  stroke={p.color}
                  strokeWidth={3}
                />
              ))}
              <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
              <XAxis dataKey="createdAt" stroke="#fff" tickFormatter={formatXAxis} />
              <YAxis stroke="#fff" />
              <Legend verticalAlign="top" height={36}  />
              <Tooltip
                contentStyle={{
                  background: '#4FA953',
                  border: '1px solid #fff',
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        </CardHeader>
        <div className={classes.datePickerWrapper}>
          <div className={classes.datePickerInnerWrapper}>
            {isLoading && (
              <div className={classes.datePickerLoader}>
                <CircularProgress size={25} />
              </div>
            )}
            <DateRangePicker
              // @ts-ignore
              onChange={setDateRangeValues}
              value={dateRangeValues}
              rangeDivider="До"
              locale="ru"
              className={classes.dateRangePicker}
            />
          </div>
        </div>
        <CardBody className={classes.cardBody}>
          {/* <h4>Транзакции по датам</h4> */}
          <div className={classes.switchWrapper}>
            <div>
              {/* {Object.entries(scatterPlotLines[selectedProviderType].availableTypes).map(
                ([value, label]) => (
                  <FormControlLabel
                    key={value}
                    control={
                      <Checkbox
                        checked={selectedTypes[value]}
                        onChange={(_, checked) =>
                          setSelectedTypes({ ...selectedTypes, [value]: checked })
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    // @ts-ignore
                    label={label}
                  />
                ),
              )} */}
            </div>
            <div>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="stats"
                  control={<Radio />}
                  label="Статистика"
                  checked={selectedStatsType === 'stats'}
                  onChange={() => setSelectedStatsType('stats')}
                />
                <FormControlLabel
                  value="balance"
                  control={<Radio />}
                  label="Баланс"
                  checked={selectedStatsType === 'balance'}
                  onChange={() => setSelectedStatsType('balance')}
                />
                <FormControlLabel
                  value="slots"
                  control={<Radio />}
                  label="Слоты"
                  checked={selectedStatsType === 'slots'}
                  onChange={() => setSelectedStatsType('slots')}
                />
                <FormControlLabel
                  value="liveDealers"
                  control={<Radio />}
                  label="Дилеры"
                  checked={selectedStatsType === 'liveDealers'}
                  onChange={() => setSelectedStatsType('liveDealers')}
                />
                <FormControlLabel
                  value="poker"
                  control={<Radio />}
                  label="Покер"
                  checked={selectedStatsType === 'poker'}
                  onChange={() => setSelectedStatsType('poker')}
                />
                <FormControlLabel
                  value="algNet"
                  control={<Radio />}
                  label="AlgNet"
                  checked={selectedStatsType === 'algNet'}
                  onChange={() => setSelectedStatsType('algNet')}
                />
                <FormControlLabel
                  value="evenbet"
                  control={<Radio />}
                  label="Evenbet"
                  checked={selectedStatsType === 'evenbet'}
                  onChange={() => setSelectedStatsType('evenbet')}
                />
                <FormControlLabel
                  value="dicechess"
                  control={<Radio />}
                  label="Dicechess"
                  checked={selectedStatsType === 'dicechess'}
                  onChange={() => setSelectedStatsType('dicechess')}
                />
              </RadioGroup>
            </div>
          </div>
        </CardBody>
        <CardFooter chart></CardFooter>
      </Card>
    </GridItem>
  );
};
