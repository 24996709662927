import React, { useEffect, useState } from 'react';
import { Box, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BeatLoader } from 'react-spinners';
import MaterialTable from 'material-table';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { errorService } from 'services/alert/services';
import { PaymentMethodService } from 'services/api/payment-method';
import {
  paymentTypeTranslations,
  currencies,
  paymentMethodType,
  paymentProviders,
} from 'constants/payment';
import { emitter } from 'shared/emitter';

import CreateOrUpdatePaymentMethod from './create-or-update-form';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};
// @ts-ignore
const useStyles = makeStyles(styles);

const mapType = (type) => {
  return paymentTypeTranslations[type] || type;
};

const mapCurrency = (currency) => {
  return currencies[currency] || currency;
};

const pageSize = 20;
const pageSizeOptions = [20, 30, 40, 50];

const currencyOptions = Object.entries(currencies).map(([value, label]) => ({
  value,
  label,
}));
const paymentTypeOptions = Object.entries(paymentTypeTranslations).map(([value, label]) => ({
  value,
  label,
}));

const paymentMethodTypeOptions = Object.entries(paymentMethodType).map(([value, label]) => ({
  value,
  label,
}));

export default function PaymentMethods() {
  const [paymentMethodList, setPaymentMethodList] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const fetchPaymentMethods = async () => {
    try {
      const response = await PaymentMethodService.getPaymentMethods();
      setPaymentMethodList(response.data.paymentMethods);
      setLoading(false);
    } catch (e) {
      errorService.sendError('Произошла ошибка' + e.message);
    }
  };

  const onRowDelete = async (rowData) => {
    try {
      await PaymentMethodService.deleteById(rowData._id);
      await fetchPaymentMethods();
    } catch (e) {
      errorService.sendError('Произошла какая то ошибка' + e.message);
    }
  };

  const openCreateOrUpdateForm = (data: any = null) => {
    emitter.emit('paymentMethods:createOrUpdateForm:open', data ? 'update' : 'create', data);
  };

  const classes = useStyles();
  return (
    <div>
      <CreateOrUpdatePaymentMethod onFetch={fetchPaymentMethods} />
      <GridContainer>
        {/* @ts-ignore */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Управление способами оплаты</p>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Box textAlign="center">
                  <BeatLoader size={16} color="#00acc1" loading={true} />
                </Box>
              ) : (
                <MaterialTable
                  title="Способы оплаты"
                  localization={{
                    body: {
                      deleteTooltip: 'Удалить',
                      editRow: {
                        deleteText: 'Вы уверены что хотите удалить?',
                      },
                    },
                    header: {
                      actions: 'Действия',
                    },
                  }}
                  actions={[
                    {
                      icon: 'add',
                      tooltip: 'Создать турнир',
                      isFreeAction: true,
                      onClick: () => openCreateOrUpdateForm(),
                    },
                    {
                      icon: 'edit',
                      tooltip: 'Обновить турнир',
                      onClick: (_, rowData) => {
                        openCreateOrUpdateForm(rowData);
                      },
                    },
                  ]}
                  columns={[
                    {
                      title: 'Название',
                      field: 'name',
                      sorting: true,
                      filtering: true,
                      searchable: true,
                    },
                    {
                      title: 'Минимальная сумма',
                      field: 'minAmount',
                      type: 'numeric',
                    },
                    {
                      title: 'Максимальная сумма',
                      field: 'maxAmount',
                      type: 'numeric',
                    },
                    {
                      title: 'Комиссия',
                      field: 'commission',
                      type: 'numeric',
                    },
                    {
                      title: 'Валюта',
                      field: 'currency',
                      lookup: currencies,
                      render: (rowData) => mapCurrency(rowData.currency),
                      editComponent: (props) => (
                        <div style={{ width: '100%' }}>
                          <Select
                            style={{ width: '100%' }}
                            name="currency"
                            value={props.value}
                            onChange={({ target: { value } }) => {
                              props.onChange(value);
                            }}
                            placeholder="Валюта"
                          >
                            {currencyOptions.map((param) => (
                              <MenuItem key={param.value} value={param.value}>
                                {param.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      ),
                    },
                    {
                      title: 'Иконка',
                      render: (rowData) => (
                        <img
                          src={rowData.iconUrl}
                          style={{ width: 40, height: 40, objectFit: 'contain' }}
                        />
                      ),
                    },
                    {
                      title: 'Провайдер',
                      field: 'provider',
                      lookup: paymentProviders,
                      editComponent: (props) => (
                        <div style={{ width: '100%' }}>
                          <Select
                            style={{ width: '100%' }}
                            name="provider"
                            value={props.value}
                            onChange={({ target: { value } }) => {
                              props.onChange(value);
                            }}
                            placeholder="Провайдер"
                          >
                            {Object.entries(paymentProviders).map(([value, label]) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      ),
                    },
                    {
                      title: 'Тип',
                      field: 'type',
                      lookup: paymentTypeTranslations,
                      render: (rowData) => mapType(rowData.type),
                      editComponent: (props) => (
                        <div style={{ width: '100%' }}>
                          <Select
                            style={{ width: '100%' }}
                            name="type"
                            value={props.value}
                            onChange={({ target: { value } }) => {
                              props.onChange(value);
                            }}
                            placeholder="Тип"
                          >
                            {paymentTypeOptions.map((param) => (
                              <MenuItem key={param.value} value={param.value}>
                                {param.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      ),
                    },
                    {
                      title: 'Курс',
                      field: 'exchangeRate',
                      type: 'numeric',
                    },
                    {
                      title: 'Порядок',
                      field: 'order',
                      type: 'numeric',
                    },
                  ]}
                  // @ts-ignore
                  data={paymentMethodList}
                  options={{
                    filtering: true,
                    pageSize,
                    pageSizeOptions,
                  }}
                  editable={{
                    onRowDelete,
                  }}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
