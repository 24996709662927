import { makeStyles } from '@material-ui/core/styles';

export const dialogStyles = makeStyles(() => ({
  dialog: {
    zIndex: 10000,
  },
  paper: {
    maxWidth: 500,
    width: '100%',
  },
  form: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
  userForm: {
    display: 'flex',
  },
  userFormInput: {
    flexGrow: 1,
    padding: 12,
  },
  add: {
    userSelect: 'none',
    cursor: 'pointer',
  },
  hidden: {
    display: "none"
  },
  topNav: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControlLabel: {
    color: 'rgba(0, 0, 0, 0.87)!important',
    fontWeight: '300',
  },

  rankIcon: {
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    padding: '15px 0'
  },
  errorIcon: {
    color: '#00acc1'
  }
}));
